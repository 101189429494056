
import React, { useState } from 'react'
import { useMediaQuery } from 'react-responsive'

import { data } from './data'
import { ITable, ITableSection } from './interface'

import * as Styled from "./styled"
import { TableDesktop, TableMobile, TableColumn } from './tables'

const Mobile = ({ children }: { children: JSX.Element }) => {
  const isMobile = useMediaQuery({ maxWidth: 599 })

  return isMobile ? children : null
}

const IsNotMobile = ({ children }: { children: JSX.Element }) => {
  const isNotMobile = useMediaQuery({ minWidth: 600 })

  return isNotMobile ? children : null
}

const TableProducts = ({ light, sizeTitle = 'large' }: ITableSection): JSX.Element => {
  const [selectedPlan, setSelectedPlan] = useState('all')

  const checkSelected = (plan: string): boolean => selectedPlan === plan

  return (
    <Styled.TableSection light={light}>
      <div className="container">
        <Styled.TableTitle sizeTitle={sizeTitle}>
          Saiba o que a Cora pode fazer <span>por sua empresa</span>
        </Styled.TableTitle>
        <Styled.TableHolder>
          <Styled.TableWrapper>
            <Mobile>
              <>
                <Styled.TableHead>
                  <TableColumn selected={checkSelected('nonmei')} onClick={() => setSelectedPlan('nonmei')} type="button" text="ME, EPP, Ltda e S/A" />
                  <TableColumn selected={checkSelected('all')} onClick={() => setSelectedPlan('all')} type="button" text="MEI" />
                </Styled.TableHead>
                <Styled.TableBody>
                  {data.map((item: ITable, index: number) => <TableMobile selectedPlan={selectedPlan} data={item} key={`table-mobile-${index}`} />)}
                </Styled.TableBody>
              </>
            </Mobile>
            <IsNotMobile>
              <>
                <Styled.TableHead>
                  <TableColumn text="" />
                  <TableColumn text="" />
                  <TableColumn text="ME, EPP, Ltda  e S/A" />
                  <TableColumn text="MEI" />
                </Styled.TableHead>
                <Styled.TableBody>
                  {data.map((item: ITable, index: number) => <TableDesktop data={item} key={`table-desktop-${index}`} />)}
                </Styled.TableBody>
              </>
            </IsNotMobile>
          </Styled.TableWrapper>
        </Styled.TableHolder>
      </div>
    </Styled.TableSection>

  )
}

export default TableProducts
