import styled from 'styled-components';

export const ButtonCTA = styled.button`
  background-color: #000;
  border-radius: 30px;
  border: 0;
  bottom: 50px;
  color: #fff;
  font-family: 'Inter';
  font-size: 1.6rem;
  font-weight: 800;
  left: 50%;
  line-height: 2.2rem;
  padding: 12px 32px;
  position: fixed;
  transform: translateX(-50%);
  z-index: 4;
  white-space: nowrap;
`;
