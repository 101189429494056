import React, { useState } from 'react'

import Layout from '../../components/Layout'
import ButtonCTA from '../../components/ButtonCTA'
import SEO from '../../components/seo'
import SideSection from '../commons/SideSection'
import SectionTable from '../../components/TableProducts'

import {
  SectionBacen,
  SectionFeature,
  SectionHero,
  SectionHeroBar,
  SectionInvestors,
  SectionNews,
  SectionPartners,
  SectionPosts,
  SectionService,
  SectionSocial,
  SectionVideos,
} from './Components'

const HomeNew = (): JSX.Element => {
  const [toggleShowForm, setToggleShowForm] = useState(false)
  const handleFormModal = (): void => setToggleShowForm(!toggleShowForm)

  return (
    <Layout pageType="home" toggle={handleFormModal} model="new">
      <SEO
        title="Cora - A Conta Digital PJ feita para quem empreende"
        description="A Cora é uma conta PJ desenvolvida especialmente para pessoas empreendedoras. Simples, sem taxas e zero burocracias. Abrir conta PJ online!"
      />
      <SectionHero handleFormModal={handleFormModal} />
      <SectionHeroBar />
      <SectionNews />
      <SectionTable />
      <SectionFeature handleFormModal={handleFormModal} />
      <SectionService />
      <SectionBacen />
      <SectionVideos />
      <SectionInvestors />
      <SectionPartners />
      <SectionPosts />
      <SectionSocial />
      <SideSection
        toggleForm={toggleShowForm}
        setToggleForm={handleFormModal}
        idFinishForm="FormFinishedHome"
        pageType="home"
      />
      <ButtonCTA handleClick={handleFormModal} />
    </Layout>
  )
}

export default HomeNew
