export const data = [
  {
    title: 'Conta digital grátis',
    description: 'Livre de taxas de abertura e manutenção',
    icon: 'account',
    plan: 'all',
  },
  {
    title: 'Emissão gratuita de boletos',
    description: 'Você decide: avulso, parcelado ou recorrente',
    icon: 'bills',
    plan: 'all',
  },
  {
    title: 'Transferências gratuitas e ilimitadas',
    description: 'Pix e TED à vontade',
    icon: 'transfers',
    plan: 'all',
  },
  {
    title: 'Gestão de cobranças',
    description: 'Crie lembretes automáticos e economize tempo',
    icon: 'charges',
    plan: 'all',
  },
  {
    title: 'Cartão de débito',
    description: 'Sem taxa de emissão e sem anuidade',
    icon: 'debit',
    plan: 'all',
  },
  {
    title: 'Cartão de crédito',
    description: 'Bandeira Visa International',
    icon: 'credit',
    plan: 'nonmei',
  },
]
