import React from 'react';
import { useMediaQuery } from 'react-responsive'
import * as Styled from './styled';

const Mobile = ({ children }: { children: JSX.Element }) => {
  const isMobile = useMediaQuery({ maxWidth: 599 })

  return isMobile ? children : null
}

const Button = ({ handleClick }: ButtonProps): JSX.Element => (
  <Mobile>
    <Styled.ButtonCTA onClick={handleClick} id='button-cta'>
      Abra sua conta PJ grátis
    </Styled.ButtonCTA>
  </Mobile>
)

export default Button;
