import React from 'react'
import { ArrowRight } from '@corabank/icons'

import { Account, Bills, Charges, Check, CreditCard, DebitCard, Transfers } from "./icons"
import { IReadMore, ITable, ITableCol, ITableData, ITableDataMobile } from './interface'

import * as Styled from "./styled"

const ReadMore = ({ cta }: IReadMore): JSX.Element => (
  <Styled.ReadMore href="https://www.cora.com.br/blog/credito-indisponivel/" target="_blank" rel="noopener noreferrer">
    <span>
      Ainda não.
    </span>
    <div>
      <strong>
        {cta}
      </strong>
      <ArrowRight size="medium" color="#FE3E6D" />
    </div>
  </Styled.ReadMore>
)

export const TableColumn = ({ text, onClick, selected, type }: ITableCol): JSX.Element => {
  if (type === 'button') {
    return <Styled.TableButton isSelected={selected} onClick={onClick}>{text}</Styled.TableButton>
  }

  return <Styled.TableCol>{text}</Styled.TableCol>
}

export const TableDesktop = ({ data }: ITableData): JSX.Element => {
  const { icon, title, description, plan }: ITable = data

  const icons = {
    account: <Account />,
    bills: <Bills />,
    transfers: <Transfers />,
    charges: <Charges />,
    debit: <DebitCard />,
    credit: <CreditCard />
  } as any

  return (
    <Styled.TableRow>
      <Styled.TableCol>
        {icons[icon]}
      </Styled.TableCol>
      <Styled.TableCol>
        <Styled.TableItem>
          {title}
        </Styled.TableItem>
        <Styled.TableDesc>
          {description}
        </Styled.TableDesc>
      </Styled.TableCol>
      <Styled.TableCol>
        <Check />
      </Styled.TableCol>
      <Styled.TableCol>
        {plan === 'nonmei' ? <ReadMore cta="Entenda o motivo" /> : <Check />}
      </Styled.TableCol>
    </Styled.TableRow>
  )
}

export const TableMobile = ({ data, selectedPlan }: ITableDataMobile): JSX.Element => {
  const { title, plan }: ITable = data

  const checkPlan = (val: string): boolean => {
    if (selectedPlan === 'nonmei' || selectedPlan === val) {
      return false;
    }
    return true;
  };

  return (
    <Styled.TableRow>
      <Styled.TableCol>
        <Styled.TableItemMobile>
          {title}
        </Styled.TableItemMobile>
      </Styled.TableCol>
      <Styled.TableCol>
        {checkPlan(plan) ? <ReadMore cta="Entenda" /> : <Check small />}
      </Styled.TableCol>
    </Styled.TableRow>
  )
}
