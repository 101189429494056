import styled, { css } from 'styled-components'

import { device } from '../../styles/breakpoints'
import {
  ITableButtonProps,
  ITableColProps,
  ITableSectionProps,
  ITableTitleProps,
} from './interface'

const Grid = css`
  align-items: center;
  color: #202020;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(2, 1fr);
  padding: 20px 0;

  @media ${device.tablet} {
    grid-template-columns: 80px 3fr repeat(2, 1.5fr);
  }
`

const Titles = css`
  font-size: 3.2rem;
  font-weight: 400;
  line-height: 4rem;
  margin: 24px 0;
  text-align: left;

  span {
    color: #fe3e6d;
    font-weight: 700;
  }
`

const TitleSmall = css`
  @media ${device.tablet} {
    font-size: 4.8rem;
    grid-column: 1 / 10;
    line-height: 6.4rem;
  }

  @media ${device.laptop} {
    grid-column: 2 / 9;
  }

  @media ${device.laptopM} {
    grid-column: 2 / 8;
  }

  @media ${device.desktop} {
    grid-column: 2 / 7;
  }
`

const TitleLarge = css`
  @media ${device.tablet} {
    font-size: 4.5rem;
    grid-column: 1 / 9;
    line-height: 5.5rem;
  }

  @media ${device.laptopM} {
    font-size: 6rem;
    line-height: 7rem;
    grid-column: 2 / 10;
  }

  @media ${device.desktop} {
    font-size: 8rem;
    line-height: 9.6rem;
    margin: 48px 0;
  }
`

export const TableSection = styled.section<ITableSectionProps>`
  background: ${({ light }) => (light ? '#FAFAFA' : '#f0f4f8')};
  padding: 50px 0;

  @media ${device.laptop} {
    padding: 120px 0;
  }
`

export const TableTitle = styled.h2<ITableTitleProps>`
  ${Titles}
  ${({ sizeTitle }) => (sizeTitle === 'large' ? TitleLarge : TitleSmall)}
`

export const TableHolder = styled.div`
  grid-column: 1 / -1;

  @media ${device.laptopM} {
    grid-column: 2 / -2;
  }
`

export const TableWrapper = styled.div`
  grid-column: 1 / -1;
`

export const TableButton = styled.button<ITableButtonProps>`
  background-color: ${({ isSelected }) =>
    isSelected ? '#fe3e6d' : 'transparent'};
  color: ${({ isSelected }) => (isSelected ? '#fff' : '#fe3e6d')};

  border-radius: 0 15px 15px 0;
  border: 2px solid #fe3e6d;

  cursor: pointer;
  font-size: 1.6rem;
  font-weight: 800;
  line-height: 2.4rem;
  padding: 20px;
  transition: all 0.5s;

  &:first-child {
    border-radius: 15px 0 0 15px;
  }
`

export const TableHead = styled.div`
  ${Grid}
  grid-gap: 0;
  align-items: stretch;

  @media ${device.tablet} {
    font-size: 1.3rem;
    font-weight: 800;
    line-height: 2.8rem;
    padding: 20px;
  }

  @media ${device.laptop} {
    font-size: 2rem;
    line-height: 2.8rem;
  }

  @media ${device.desktop} {
    font-size: 2.8rem;
    line-height: 3.8rem;
  }
`

export const TableBody = styled.div`
  background-color: #fff;
  border-radius: 5px;
  padding: 30px;
`

export const TableRow = styled.div`
  ${Grid}

  &:not(:last-child) {
    border-bottom: 1px solid #dee4e9;
  }
`

export const TableCol = styled.div<ITableColProps>`
  text-align: center;

  &:first-child {
    text-align: left;
  }

  @media ${device.tablet} {
    &:nth-child(2) {
      text-align: left;
    }
  }
`

export const TableItem = styled.h3`
  color: #202020;
  font-size: 2rem;
  font-weight: 800;
  line-height: 2.5rem;

  @media ${device.laptop} {
    font-size: 2.2rem;
    line-height: 2.8rem;
  }

  @media ${device.desktop} {
    font-size: 2.8rem;
    line-height: 3.8rem;
  }
`

export const TableItemMobile = styled.h3`
  color: #202020;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.4rem;
`

export const TableDesc = styled.p`
  color: inherit;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.4rem;
`

export const ReadMore = styled.a`
  color: #202020;
  display: flex;
  flex-direction: column;
  font-size: 1.4rem;
  font-weight: 800;
  line-height: 1;
  text-decoration: none;

  @media ${device.tablet} {
    font-size: 1.6rem;
  }

  @media ${device.laptop} {
    font-size: 2.2rem;
    font-weight: 800;
    line-height: 3.2rem;
  }

  & svg {
    transition: all 0.5s;
  }

  &:hover svg {
    transform: translateX(5px);
  }

  strong {
    color: #fe3e6d;
    font-size: 1.2rem;

    @media ${device.laptop} {
      font-size: 1.4rem;
      line-height: 2.4rem;
    }
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
